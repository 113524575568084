<template>
  <div 
  id="g-recaptcha"
  class="g-recaptcha"
  :data-sitekey="sitekey">
  </div>
</template>

<script>
export default {
  data () {
    return {
      sitekey: process.env.VUE_APP_recaptcha_v2_sitekey,
      widgetId: 0
    }
  },
  methods: {
    execute () {
      //window.grecaptcha.execute(this.widgetId)
    },
    reset () {
      //window.grecaptcha.reset(this.widgetId)
      if(window.grecaptcha){
        window.grecaptcha.reset();
      }
      
    },
    render () {
      if (window.grecaptcha) {
        this.widgetId = window.grecaptcha.render('g-recaptcha', {
          sitekey: this.sitekey,
          size: 'default',// or invisible
          // the callback executed when the user solve the recaptcha
          callback: (response) => {
            // emit an event called verify with the response as payload
            this.$emit('verify', response)
            // reset the recaptcha widget so you can execute it again
            //this.reset() 
          }
        })
      }
    }
  },
  computed: {

  },
  mounted () {
    // render the recaptcha widget when the component is mounted
    this.render()
  }
}
</script>