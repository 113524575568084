<template>
  <div class="message-page">
    <v-main>
      <v-container>
        <v-overlay :value="loading" color="white" opacity="1">
          <div class="spinner-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </v-overlay>

        <v-row v-if="!canPayment" align="center" justify="center">
          <v-col cols="12" lg="7">
            <div class="d-block text-center mt-16">
              <img alt="logo" src="@/assets/logo_gifty.svg" width="120"/>
            </div>

            <v-card class="rounded-lg shadow mt-5">
              <v-card-text class="text-center pa-6">
                <v-icon color="red" size="80">mdi-alert-circle</v-icon>
                <h2 class="my-8 red--text">Alert !</h2>
                <p class="font-weight-medium card-text">
                  Le montant
                  <span class="gifty--text">
                    {{ CurrencyFormatting(order.total) }} DZD
                  </span>
                  est hors des limites autorisées.
                </p>
              </v-card-text>
            </v-card>


            <p class="pt-10 text-center">
              © Copyright {{ new Date().getFullYear() }}
              <a
                  class="text-primary"
                  href="https://gifty-dz.com/"
                  target="_blank"
              >Gifty Algérie</a
              >
              Tous droits réservés.
            </p>
          </v-col>
        </v-row>

        <v-row v-else align="center" justify="center">
          <v-col cols="12" lg="7">
            <div class="d-block text-center mt-16">
              <img alt="logo" src="@/assets/logo_gifty.svg" width="120"/>
            </div>

            <div v-if="error">
              <v-card class="rounded-lg shadow mt-5">
                <v-card-text class="text-center pa-6">
                  <v-icon color="red" size="80">mdi-alert-circle</v-icon>
                  <h2 class="my-5">Page not found !!</h2>
                  <img alt="" src="@/assets/404.svg" width="200"/>
                  <p class="font-weight-medium card-text">
                    Nous n'avons pas trouvé la page que vous recherchez
                  </p>
                </v-card-text>
              </v-card>
            </div>

            <div v-else>
              <v-card class="rounded-lg shadow mt-5">
                <v-card-text class="pa-6" v-if="order">
                  <h2 class="mt-5 mb-8 text-center">Paiement par carte</h2>

                  <v-simple-table class="table-border mb-5">
                    <template v-slot:default>
                      <tbody>
                      <tr>
                        <td class="font-weight-medium">Nom de client</td>
                        <td>{{ order.user.name }}</td>
                      </tr>

                      <tr>
                        <td class="font-weight-medium">Téléphone</td>
                        <td>{{ order.user.phone }}</td>
                      </tr>

                      <tr>
                        <td class="font-weight-medium">Email</td>
                        <td>{{ order.user.email }}</td>
                      </tr>

                      <tr>
                        <td class="font-weight-medium">Service</td>
                        <td class="d-flex align-center pt-2 pb-2">
                          <img
                              :src="getImageLink(order.service.image)"
                              alt=""
                              class="mr-2 pa-1"
                              width="50"
                          />
                          {{ order.service.name }}
                        </td>
                      </tr>

                      <tr>
                        <td class="font-weight-medium">Montant</td>
                        <td class="font-weight-bold pink--text darken-4">
                          {{ CurrencyFormatting(order.data.amount) }} DZD
                        </td>
                      </tr>

                      <tr v-if="order.total_promos > 0">
                        <td class="font-weight-medium">Promotion</td>
                        <td class="font-weight-medium blue--text darken-4">
                          {{ CurrencyFormatting(order.total_promos) }} DZD
                        </td>
                      </tr>

                      <tr v-if="order.fees.length > 0">
                        <td class="font-weight-bold text-center" colspan="2">
                          Frais Service
                        </td>
                      </tr>

                      <template v-if="order.fees.length > 0">
                        <tr v-for="fee in order.fees" :key="fee.name">
                          <td class="font-weight-medium">{{ fee.name }}</td>
                          <td class="font-weight-medium gifty--text">
                            {{ CurrencyFormatting(fee.value) }} %
                          </td>
                        </tr>
                      </template>

                      <tr>
                        <td class="font-weight-medium">Mode de paiement</td>
                        <td class="d-flex align-center py-2">
                          <v-img
                              :src="require('@/assets/carte_CIB.jpg')"
                              alt=""
                              class="mt-2 mb-2 mr-2"
                              contain
                              max-width="70"
                          />
                          <span class="font-weight-bold"> CIB/Edahabia</span>

                        </td>
                      </tr>

                      <tr>
                        <td>
                          <h3>Somme finale</h3>
                        </td>
                        <td class="success--text darken-4">
                          <h3>{{ CurrencyFormatting(order.total) }} DZD</h3>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <Recaptcha @verify="recaptchaVerified($event)"/>

                  <div class="d-flex align-center">
                    <v-checkbox v-model="acceptTerms" class="mr-2">
                      <template v-slot:label>
                        <div>J'accepte les</div>
                      </template>
                    </v-checkbox>

                    <router-link :to="{ name: 'e_payments.terms' }" target="_blank">
                      Condition général d'utilisation
                    </router-link>
                  </div>


                  <v-btn
                      :disabled="
                      !(acceptTerms && recaptcha_verified && payment_mode)
                    "
                      :loading="btnLoading"
                      block
                      class="rounded-lg"
                      color="primary"
                      depressed
                      large
                      @click="pay"
                  >
                    Confirmer
                  </v-btn>
                </v-card-text>
                <v-card-text></v-card-text>
              </v-card>
            </div>

            <v-layout align-center justify-center>
              <v-flex shrink>
                <v-img
                    :src="require('@/assets/green_number.png')"
                    class="mt-5 pointer"
                    width="200"
                    @click="call()"
                />
              </v-flex>
            </v-layout>

            <p class="pt-10 text-center">
              © Copyright {{ new Date().getFullYear() }}
              <a
                  class="text-primary"
                  href="https://gifty-dz.com/"
                  target="_blank"
              >Gifty Algérie</a
              >
              Tous droits réservés.
            </p>
          </v-col>
        </v-row>

      </v-container>
    </v-main>
  </div>
</template>

<script>
import axios from "axios";
import Recaptcha from "../gifty/services/Recaptcha.vue";

export default {
  components: {Recaptcha},
  data() {
    return {
      loading: true,
      btnLoading: false,
      error: "",
      order: null,
      form: {
        recaptcha_token: "",
      },
      recaptcha_verified: false,
      acceptTerms: false,
      canPayment: true,
      payment_mode: "cib",
    };
  },
  methods: {
    fectchData() {
      axios
          .get(
              process.env.VUE_APP_BASE_URL +
              "v1/e-payments/" +
              this.$route.params.token
          )
          .then((res) => {
            this.order = res.data.data;
            this.canPayment = res.data.canPayment;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.error = "Une erreur s'est produite veuillez corriger.";
          });
    },
    recaptchaVerified(token) {
      this.form.recaptcha_token = token;
      this.recaptcha_verified = true;
    },
    pay() {
      this.btnLoading = true;
      axios
          .post(
              process.env.VUE_APP_BASE_URL +
              "v1/e-payments/register/" +
              this.$route.params.token,
              this.form
          )
          .then((res) => {
            this.btnLoading = false;
            this.$successMessage = "Opération terminée avec succès";
            window.location = res.data.data.redirect_url;
          })
          .catch((err) => {
            this.btnLoading = false;
            if (err.response && err.response.data && err.response.data.message) {
              this.$errorMessage = err.response.data.message;
            }
          });
    },
    call() {
      window.open("tel:3020", "_self");
    },
  },
  mounted() {
    this.fectchData();
  },
};
</script>

<style>
.card-text {
  line-height: 1.9;
}
</style>